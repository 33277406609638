@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.candidateBox{width: 100%;}
.candidateBox .chakra-text{font-size: 16px;}
.candidateBox strong{display: block; font-size: 20px; color: rgb(8, 36, 99); margin-bottom: 5px;margin-top: 10px;}
.candidateBox .candidateHeading{font-size: 30px; font-weight: 600; color: #082463;padding-bottom: 10px;}
.candidateBox hr{margin-bottom: 20px;}